<template>
  <div>
    <!--div class="hovering__pad">
      <p class="fs-16">Как отправить заявки блогерам на сайте</p>
      <b-embed></b-embed>
    </!--div-->
    <div class="d-flex justify-content-between mb-4 align-items-center">
      <h2 class="d-inline-block">
        В корзине {{ userCart.orders_length }}
        {{ literal.declension(userCart.orders_length, 'блогер') }}
      </h2>
      <b-button
        variant="outline-default"
        :disabled="!userCart.orders_length"
        @click="flushCart"
        class="ml-auto"
        >Очистить</b-button
      >
    </div>
    <template v-if="reviews || formats">
      <template v-if="userCart.orders_length">
        <b-button
          v-if="ordersLeft >= 0"
          @click="setOrderWindow"
          :disabled="!userCart.orders_length"
          variant="yellow"
          class="py-3 w-100 mb-2"
          >Отправить запрос блогерам бесплатно<br /><span class="gray-text fs-12"
            >Cегодня осталось {{ userCart.orders_left }} бесплатных запросов на рекламу</span
          ></b-button
        >
        <b-button v-else @click="setOrderWindow" variant="outline-default" class="py-3 w-100 mb-2"
          >Отправить запрос блогерам за {{ Math.abs(ordersLeft) }}
          <img class="ml-1 d-inline-block mb-1" src="@main/assets/img/svg/energy.svg" alt=""
        /></b-button>
        <p class="fs-12 mb-4">
          Вы можете отправлять до 20 запросов на рекламу в день. Каждый следующий запрос сверх 20
          будет тратить 1 проверку блогера.
        </p>
      </template>
      <div v-if="reviews || formats" class="hovering__pad mb-4">
        <div v-if="formats">
          <div class="p-3 order--cart__item" v-for="format in formats" :key="format.id">
            <b-button
              class="mb-2"
              :href="`https://instagram.com/${format.blogger}`"
              variant="link"
              >{{ format.blogger }}</b-button
            >
            <p class="mb-3">
              <span class="fw-500">{{ getPrType(format) }}</span> —
              {{ format.uPrice.toLocaleString().replace(',', ' ') }} ₽
            </p>
            <div class="d-flex flex-row justify-content-left">
              <b-button
                @click="subtractCartFormats(format.id)"
                class="mr-3"
                variant="outline-default"
              >
                ❌ Убрать</b-button
              >
              <b-button
                @click="
                  () => {
                    toggleStatisticCardModal({
                      open: true,
                      context: { instaname: format.blogger },
                    });
                  }
                "
                variant="outline-default"
                >🦸 В карточку</b-button
              >
            </div>
          </div>
        </div>
        <div v-if="reviews">
          <div style="background: #fffce3" class="py-3 px-3 mb-2 not-reg-blck">
            <h2 class="mb-2">Блогеры, ещё не зарегистрировавшие свою карточку на сайте</h2>
            <p class="gray-text">
              Мы отправим им запрос на рекламу, который они увидят после регистрации на сайте
            </p>
          </div>
          <div class="p-3 order--cart__item" v-for="review in reviews" :key="review.advertiser">
            <b-button
              class="mb-3"
              :href="`https://instagram.com/${review.advertiser}`"
              variant="link"
              >{{ review.advertiser }}</b-button
            ><br />
            <b-button @click="subtractCartReviews(review.id)" variant="outline-default"
              >❌ Убрать</b-button
            >
          </div>
        </div>
      </div>
      <div v-if="userCart.orders_length">
        <b-button
          v-if="ordersLeft >= 0"
          @click="setOrderWindow"
          :disabled="!userCart.orders_length"
          variant="yellow"
          class="py-3 w-100 mb-2"
          >Отправить запрос блогерам бесплатно<br /><span class="gray-text fs-12"
            >Cегодня осталось {{ userCart.orders_left }} бесплатных запросов на рекламу</span
          ></b-button
        >
        <b-button v-else @click="setOrderWindow" variant="outline-default" class="py-3 w-100 mb-2"
          >Отправить запрос блогерам за {{ Math.abs(ordersLeft) }}
          <img class="ml-1 d-inline-block mb-1" src="@main/assets/img/svg/energy.svg" alt=""
        /></b-button>
        <p class="fs-12">
          Вы можете отправлять до 20 запросов на рекламу в день. Каждый следующий запрос сверх 20
          будет тратить 1 проверку блогера.
        </p>
      </div>
      <b-button v-else :to="{ name: 'reviews' }" variant="yellow" class="py-3 w-100 mb-2"
        >Найти блогеров для рекламы</b-button
      ></template
    >
    <Loader v-else />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import bot from '@main/api/bot';
import Loader from '@main/components/Loader.vue';
import reviews from '@main/api/reviews';
import AdvertisingApplicationForm from '@main/components/forms/AdvertisingApplicationForm.vue';

export default {
  data: () => ({
    reviews: null,
    formats: null,
  }),
  components: {
    Loader,
  },
  methods: {
    ...mapMutations('notifications', ['setWindow']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    ...mapActions(['updateUserCart']),
    setOrderWindow() {
      this.setWindow({
        backMode: true,
        contentClass: 'overflow-auto pb-4',
        contentComponent: AdvertisingApplicationForm,
        titleAttrs: {
          style: 'border-bottom: 1px solid #d5dfe4; font-size: 18px;',
          class: 'px-4 pb-2 pt-2',
        },
        title: 'Заполните поля заявки, чтобы блогеры смогли вам ответить',
        ccWrapperAttrs: { class: 'px-4 pb-4' },
      });
    },
    async subtractCartFormats(id) {
      await this.updateUserCart({
        ...this.userCart,
        formats: this.lodash.difference(this.userCart.formats, [id]),
      });
    },
    async subtractCartReviews(id) {
      await this.updateUserCart({
        ...this.userCart,
        reviews: this.lodash.difference(this.userCart.reviews, [id]),
      });
    },
    async flushCart() {
      await this.updateUserCart({
        ...this.userCart,
        reviews: [],
        formats: [],
      });
    },
    async loadFullData() {
      if (!this.userCart.reviews || !this.userCart.reviews.length) {
        this.reviews = null;
      } else {
        const revs = await reviews.list({ id: this.userCart.reviews });
        this.reviews = revs.results;
      }
      if (!this.userCart.formats || !this.userCart.formats.length) {
        this.formats = null;
      } else {
        const formats = await bot.formats.list({ id: this.userCart.formats });
        this.formats = formats.results;
      }
    },
    getPrType(prType) {
      const { choices } = this.options.pr_types.child.children.kind;
      const values = choices.filter((val) => val.value === prType.kind);
      let namestring = values[0].display_name;
      if ((values[0].value === '1' || values[0].value === '4') && prType.duration) {
        namestring = `${namestring} ${prType.duration ? prType.duration : ''} сек.`;
      }
      return namestring;
    },
  },
  computed: {
    ...mapState(['userCart', 'bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    ordersLeft() {
      return this.userCart.orders_left - this.userCart.orders_length;
    },
  },
  async mounted() {
    await this.loadFullData();
    console.log(this.reviews);
  },
  watch: {
    userCart(val) {
      this.loadFullData();
    },
  },
};
</script>

<style lang="scss" scoped>
.order--cart__item:not(:last-child) {
  border-bottom: 1px solid #d5dfe4;
}
.not-reg-blck:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
